body {
  font-family: 'Poppins', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700&display=swap');

.boton{
  background: rgb(224, 224, 224);
  color: #000;
  padding: 10px;
  margin: 5px;
  border: 0px;
  cursor: pointer;
}

.boton:hover{
  background: rgb(234, 234, 234);
  color: #000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



form {
  display: flex;
  flex-direction: column;
  margin-top: 20px
}

form label {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 10px;
  font-weight: 200;

}

.tag-error {
  background: #ffd4d4;
  padding: 10px;
  max-width: 500px;
  margin-top: 30px;
  margin-bottom: -10px;
}

form input,
select {
  height: 50px;
  border-radius: 6px;
  margin-top: 5px;
  padding-left: 5px;
  outline: none;
  font-size: 15px;
  font-weight: 200;
  border: 1px solid rgb(206, 206, 206);
  font-family: 'Poppins', sans-serif !important;
}

form [type="file"i] {
  border: none
}

form button {
  max-width: 400px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}